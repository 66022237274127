.apply {
    color: #4492EC;
    font-weight: bold;
    height: 26px;
    margin-left: 12px;
    /* padding-right: 36px; */
    /* position: relative; */
}

.clear {
    color: #DBDBDB;
    font-weight: 400;
    height: 26px;
    /* padding-right: 36px; */
    /* position: relative; */
}

.buttons {
    margin-left: auto;
}
