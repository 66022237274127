.users-table {
    margin: 24px 0 24px 0;
}

.users-table.empty {
    align-items: center;
    border: 1px #dcdcdc solid;
    display: flex;
    height: 100px;
    justify-content: center;
}

/*.users .users-header .pagination {*/
/*    display: inline-flex;*/
/*    width: 80%;*/
/*}*/