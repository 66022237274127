.form.user-form .custom-role-select {
    display: inline-block;
    height: 35px;
    margin-top: 11px;
    padding-right: 25px;
    position: relative;
    width: 100%;
    background-color: #454545;
    border-radius: 2px;
    overflow: hidden;
}

.user .user-role .custom-role-select {
    display: inline-block;
    height: 35px;
    padding-right: 25px;
    position: relative;
    width: 100%;
    background: rgb(236, 236, 237);
    border-radius: 17.5px;
    overflow: hidden;
}

.custom-role-select::after {
    background-image: url(../../../../../media/icon-caret-down-red.svg);
    content: '';
    display: block;
    height: 8px;
    position: absolute;
    right: 20px;
    top: 15px;
    width: 12px;
    pointer-events: none;
}

.custom-role-select:checked:after {
    transform: translate(-50%, -50%) rotate(180deg) scaleX(-1) scaleY(1.75);
}

.form.user-form .custom-role-select select {
    box-shadow: none;
    color: #FFFFFF;
    font-family: alternate-gothic-no-2-d, sans-serif;
    font-size: 13px;
    letter-spacing: 0.5px;
    -webkit-appearance: none;
    width: 100%;
    background: none transparent;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    outline: none;
    padding: 10px 20px;
    cursor: pointer;
    display: block;
}

.form.user-form .custom-role-select select:hover {
    background-color: #585858;
    outline: none;
}

.user .user-role .custom-role-select select {
    box-shadow: none;
    color: rgb(117, 117, 117);
    font-family: alternate-gothic-no-2-d, sans-serif;
    font-size: 15px;
    letter-spacing: 0.5px;
    -webkit-appearance: none;
    width: 100%;
    background: none transparent;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    outline: none;
    padding: 10px 20px;
    cursor: pointer;
    display: block;
}

.user .user-role .custom-role-select select:hover {
    color: #000000;
}

