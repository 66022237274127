@keyframes delayedLoader {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

/* OVERLAY (FULL SCREEN) -------------------------------------------------------
------------------------------------------------------------------------------*/
.show-loader-overlay::after {
    background: rgba(255, 255, 255, 0.9) url("../../../media/img/misc/spinner-grey.gif") center center no-repeat;
    background-size: 100px;
    content: '';
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.show-singlePhoto-loader-overlay::after {
    background: rgba(255, 255, 255, 0.9) url("../../../media/img/misc/spinner-grey.gif") center center no-repeat;
    background-size: 100px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}