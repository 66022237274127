.enlarged-wrapper {
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    bottom: 0;
    color: #fff;
    display: none;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position:fixed;
    top: 0;
    right: 0;
    text-align: center;
    z-index: -3;
}

.enlarged-wrapper.is-enlarged {
    cursor: pointer;
    display: flex;
    z-index: 100;
}

.enlarged-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 700px;
    width: 100%;
}

@media screen and (max-height: 780px) {

    .enlarged-inner {
        max-width: 400px;
    }

}

.enlarged-inner img {
    max-height: 70%;
    object-fit: contain;
    height: auto;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.enlarged-wrapper .details {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.enlarged-wrapper .details  ul {
    font-size: 13px;
    margin: 0;
    padding: 0 20px 0 0;
    text-align: left;
}

.enlarged-wrapper .details  ul:last-of-type {
    padding-right: 0;
}

.enlarged-wrapper .details ul li {
    list-style: none;
}

.enlarged-wrapper .details ul li span {
    font-weight: bold;
}

.enlarged-wrapper .apply-button {
    left: 0;
    position: absolute;
    right: 0;
}