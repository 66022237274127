.sortBy-dropdown-wrapper {
    height: 37px;
    width: 180px;
    display: flex;
    align-items: center;
}

.sortBy-dropdown-wrapper svg {
    transition: 0.2s;
    -webkit-transition: 0.2s;
}

.sortBy-dropdown-wrapper svg:hover {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
}

.sortBy-dropdown {
    height: 37px;
    width: 142px;
    /*padding-right: 4px;*/
}

@media screen and (max-width: 550px) {

    .sortBy-dropdown-wrapper {
        display: none;
        pointer-events: none;
    }

}
