div[disabled] {
    pointer-events: none;
}

.tile-body[disabled] {
    pointer-events: none;
    opacity: 1.0;
    background-image: url('../../media/maintenance.jpg');
    background-size: cover;
    background-position: 60% 100%;
    background-repeat: no-repeat;
    display: block;
    z-index: 2;
    color: #2C2C2C;
}

.tilegrid {
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1500px;
    width: 100%
}

.tile, .tilegrid {
    display: -ms-flexbox;
    display: flex
}

.odd.tile {
    flex: 1 1 100% !important;

}

.tile {
    border: 1px solid #fff;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 310px;
    text-transform: uppercase;
    -webkit-transition: all .38s ease-in-out;
    -o-transition: all .38s ease-in-out;
    transition: all .38s ease-in-out
}

.tile:first-child, .tile:nth-child(2) {
    border-top: none
}

@media screen and (max-width: 740px) {
    .tile {
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        height: 210px
    }
}

.tile-body {
    background: #111;
    color: #fff;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1;
    flex: 1 1;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-decoration: none;

    -webkit-transition: all .38s ease-in-out;
    -o-transition: all .38s ease-in-out;
    transition: all .38s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.tile-body .tile-image {
    background-position: 50%;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    opacity: .35;
    top: 0;
    z-index: 1;
}

.tile-body .image.tall {
    background-position: 50% 80%;
}

.tile-body:hover {
    background: red;
}

.tile-body:hover .image {
    opacity: .1
}

.touch .tile-body:hover {
    background: #111
}

.touch .tile-body:hover .image {
    opacity: .35
}

.tile-body h3 {
    display: block;
    font-family: proxima-soft, "sans-serif";
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 2px;
    margin: auto 0
}

.tile-body .subname, .tile-body h3 {
    padding: 15px;
    position: relative;
    z-index: 2
}

.tile-body .subname {
    display: inline-block;
    text-transform: none
}

@media screen and (max-width: 740px) {
    .tile-body h3 {
        font-size: 1.9rem;
        /*margin-top: 0*/
    }
}

.tile-footer {
    background: #383838;
    color: #fff;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-family: proxima-soft, "sans-serif";
    font-size: .8rem;
    margin-top: auto;
    padding: 15px;
    text-decoration: none;
    -webkit-transition: all .38s ease-in-out;
    -o-transition: all .38s ease-in-out;
    transition: all .38s ease-in-out;
    width: 100%
}

.tile-footer:hover {
    background-color: red
}

.touch .tile-footer:hover {
    background: #383838
}

.tile-footer small {
    font-weight: 300
}

.tile-footer span {
    color: #fff;
    display: inline-block;
    font-weight: 700;
    font-size: .8rem;
    padding-top: 2px;
    text-decoration: none
}

@media screen and (max-width: 550px) {
    .tile-footer span {
        font-size: .7rem
    }
}