.search-bar {
    border: 1px #4492EC solid;
    background: #4492EC url('../../../media/img/icons/search.svg') no-repeat center right 10px;
    cursor: pointer;
    display: inline-block;
    padding: 0 34px 0 0;
    /*width: 20%;*/
    transition: background-color .15s ease-in-out;
}

.search-bar:hover {
    background-color: #3785df;
    transition: background-color .15s ease-in-out;
}

.search-input {
    border: none;
    background-color: #fff;
    height: 27px;
    padding: 0 10px 0 10px;
    width: 100%;
}

.search-input:focus {
    outline: none;
}