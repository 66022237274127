.form.user-form .form-footer {
    margin-top: 20px;
    margin-bottom: 50px;
}

.form.user-form .checkbox-wrapper {
    width: calc(100% / 3)
}

.form.user-form .checkbox-group:first-of-type {
    padding-top: 0;
}


/*===========Invite Link===========*/
a.invite {
    color: #156fc6;
    font-weight: bold;
}

a:link.invite {
    text-decoration: none;
 }

a:hover.invite {
    text-decoration: underline;
}
