.main {
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    transition: transform 900ms cubic-bezier(0.23, 1, 0.32, 1), opacity 900ms cubic-bezier(0.23, 1, 0.32, 1) 200ms;
    width: 100%;
    z-index: 2;
}


/* !MAIN HEADER ----------------------------------------------------------------
------------------------------------------------------------------------------*/

.main-header {
    align-items: center;
    background: #fff;
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    font-size: 13px;
    height: 87px;
    padding: 0 24px;
}

.main-header span {
    display: inline-block;
    padding: 0 24px;
}

.main-header .page-nav {

}

.main-header .page-nav li {
    display: inline-block;
    list-style: none;
}

.main-header .page-nav li {
    margin-right: 5px;
}

.main-header .page-nav li a {
    border: 1px solid transparent;
    border-radius: 14.5px;
    color: #8d8d8d;
    display: block;
    font-size: 13px;
    font-weight: bold;
    height: 29px;
    line-height: 25px;
    text-align: center;
    width: 90px;
}

.main-header .page-nav li a.is-active {
    border: 1px solid #858585;
    color: #2C2C2C;
}

.main-header .page-nav li a:not(.is-active):hover {
    background-color: #F6F6F6;
}


.main-header .planning-period {
    margin-left: auto;
}

.main-header .planning-ends {
    font-weight: 700;
}

.main-header .planning-ends {
    border-left: 1px solid #c2c2c2;
    border-right: 1px solid #c2c2c2;
}

.main-header .name-role {
    padding-right: 0;
}

.main-header .logout {
    color: #4A90E2;
    font-weight: 700;
    padding: 0 13px;
}


/* !MAIN BODY ------------------------------------------------------------------
------------------------------------------------------------------------------*/

.main-body {
    flex: 1;
    padding: 24px;
}


/* !MAIN FOOTER ----------------------------------------------------------------
------------------------------------------------------------------------------*/

.main-footer {
    align-items: center;
    display: flex;
    height: 87px;
    justify-content: space-between;
    padding: 0 24px;
    position: relative;
    width: 100%;
}

.main-footer .stroke {
    background: #DCDCDC;
    border: none;
    height: 1px;
    margin: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 48px);
}

.main-footer .copyright {
    opacity: 0.5;
}

.main-footer .scroll-top {
    background: #fff url('../../../../../media/img/icons/scrollToTop_carrot.svg') center center no-repeat;
    background-size: 15px auto;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 6px 13px 0 rgba(98, 98, 98, 0.33);
    cursor: pointer;
    display: inline-block;
    height: 40px;
    transition: all 110ms ease-in-out;
    width: 40px;
}

.main-footer .scroll-top:hover {
    background: #E1EFFF url('../../../../../media/img/icons/scrollToTop_carrot.svg') center center no-repeat;
    background-size: 15px auto;
    box-shadow: 0 3px 4px 0 rgba(98, 98, 98, 0.33);
}

.input-wrapper.small {
    width: 200px;
}

.input-wrapper.small > .field-wrapper {
    text-align: center;
}
