.drawer {
    background: #262626;
    color: #fff;
    height: 100%;
    position: fixed;
    overflow: auto;
    width: 790px;
    z-index: 1;
}

.drawer-expanded-narrow .drawer {
    width: 406px;
}
