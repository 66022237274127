/* custom checkbox */

.checkbox-wrapper {
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    height: 20px;
    line-height: 22px;
    margin: 12px 0;
    padding: 0 0 0 30px;
    position: relative;
    width: 20%;
}

.checkbox-wrapper.wide-column {
    width: 50% !important;
}

.checkbox-wrapper.checkbox-column {
    display: block;
    font-size: 13px;
    height: 20px;
    line-height: 22px;
    margin: 28px 0;
    padding: 0 0 0 30px;
    position: relative;
    width: 100%;
}

.checkbox-wrapper input[type='checkbox'] {
    display: none;
}

.checkbox-wrapper span {
    background-color: #434343;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: inline-block;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    transition: background-color 50ms ease-in-out;
    width: 20px;
}

.checkbox-wrapper:hover span {
    background-color: #585858;
}

.checkbox-wrapper .selectAll {
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.checkbox-wrapper input:checked ~ span {
    background: #4492EC url('../../../../../media/img/icons/check.svg') center center no-repeat;
    background-size: 13px auto;
}

.checkbox-wrapper input:disabled ~ span {
    background: #43434375;
    background-size: 13px auto;
    cursor: auto;
}

.checkbox-wrapper input:disabled ~ span ~ .selectAll {
    color: #ffffff75;
    cursor: auto;
}

.checkbox-wrapper:hover input:checked ~ span {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.9);
}

.checkbox-group {
    width: 100%;
    padding-top: 30px;
}

.checkbox-group-header {
    align-items: center;
    border-bottom: 1px solid #494949;
    display: flex;
    margin-bottom: 15px;
    width: 100%;
}

.checkbox-group-header .checkbox-wrapper {
    margin-left: auto;
}

.checkbox-group-body {
    padding-top: 9px;
}

.checkbox-group-empty {
    margin: 0;
    padding-top: 16px;
    text-align: center;
}

/* ===================== Disabled ======================== */

.checkbox-group.disabled {
    cursor: not-allowed;
}

.checkbox-group .checkbox-group-header.disabled {
    pointer-events: none;
}

.checkbox-group .checkbox-group-body.disabled {
    pointer-events: none;
}

