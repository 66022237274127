.footer {
    width: 100%;
    bottom: 0;
    background-color: #111;
    color: #FFFFFF;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.footer-inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 1500px;
    padding: 20px 15px;
    width: 100%;
}

.footer.has-links .footer-inner {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: space-around;
}

.footer .footer-inner ul {
    display: -ms-flexbox;
    display: flex;
    font-size: .9rem;
    margin: 0;
    padding: 0;
}