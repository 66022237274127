* {
    box-sizing: border-box;
}

.brand-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 100px 0px;
    flex-wrap: wrap;
}

.brand-column {
    width: 500px;
    margin: 50px 25px;
    /*float: left;*/
    height: 300px;
    background-size: cover;
    border-style: solid;
    border-color: #cccccc;
    display: flex;
}

@media screen and (max-width: 740px) {
    .brand-row {
        padding: 10px 10px;
        display: block;
    }

    .brand-column {
        width: 100%;
        padding-top: 60%;
        height: auto;
        margin: 10px 0px;
    }

    .brand-column > * {
        width: 100%;
    }
}

.brand-column > * {
    width: 500px;
    display: block;
}

.diageo {
    background-image: url('../../../media/Reports/SPIRITS.jpg');
}

.dbc {
    background-image: url('../../../media/Reports/DBC.png');
}

.experiential {
    background-image: url('../../../media/Reports/Experiential.png');
}