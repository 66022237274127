* {
    box-sizing: border-box;
}

.row {
    width: 100%;
    display: flex;
    overflow-x: auto;
    /*flex-direction: row;*/
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: block;
}

.row::-webkit-scrollbar {
    height: 0px; /* remove scrollbar space */
    background: transparent; /* optional: just make scrollbar invisible */
}

.column {
    width: 125px;
    margin: 8px;
    /*float: left;*/
    height: 90px;
    background-size: cover;
    border-style: solid;
    border-color: #cccccc;
    display: flex;
}

.column > * {
    width: 125px;
    display: block;
}

.summary {
    background-image: url('../../media/Reports/SPIRITS-SUMMARY-FINAL.png');
}

.pacing {
    background-image: url('../../media/Reports/SPIRITS-PACING-FINAL.png');
}

.execution {
    background-image: url('../../media/Reports/SPIRITS-EXECUTION-FINAL.png');
}

.onprem {
    background-image: url('../../media/Reports/SPIRITS-ONPREM-FINAL.png');
}

.offprem {
    background-image: url('../../media/Reports/SPIRITS-OFFPREM-FINAL.png');
}

.special {
    background-image: url('../../media/Reports/SPIRITS-SPECIAL-FINAL.png');

}

.finance {
    background-image: url('../../media/Reports/SPIRITS-FINANCE-FINAL.png');
}

.planning {
    background-image: url('../../media/Reports/SPIRITS-PLANNING-FINAL.png');
}

.dbc_execution {
    background-image: url('../../media/Reports/DBC-EXECUTION-FINAL.png');
}

.dbc_onprem {
    background-image: url('../../media/Reports/DBC-ONPREM-FINAL.png');
}

.dbc_offprem {
    background-image: url('../../media/Reports/DBC-OFFPREM-FINAL.png');
}

.column:hover {
    /*fill-opacity: 80%;*/
    /*background-image: none;*/
    opacity: 0.8;
    background-color: rgba(255, 255, 255, .8);
}

hr {
    margin-bottom: 0px;
}

/*-------------------- Dashboard Viewer --------------------*/
.dashboardViewer {

}

.openNewWindow {
    position: absolute;
    right: calc((100% - 1200px) / 2);
    cursor: pointer;
}

.dashboardViewer iframe {
    width: 100% !important;
}

.openNewWindow:hover {
    transform: scale(1.2);
}

@media screen and (max-width: 1200px) {
    .openNewWindow {
        right: 2px;
    }
}