.form {

}

.form-body {
    padding: 35px 40px;
}

.form-section {
    display: flex;
    flex-wrap: wrap;
}

.form-section .react-tabs {
    width: 100%;
}

/* !FORM HEADER ----------------------------------------------------------------
------------------------------------------------------------------------------*/

.form-header {
    align-items: center;
    background: #373737;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    height: 87px;
    padding: 0 40px;
}

.tab-links {
    margin: 0;
    padding: 0;
}

.tab {
    border: 1px solid transparent;
    border-radius: 14.5px;
    cursor: pointer;
    display: inline-block;
    height: 29px;
    line-height: 27px;
    list-style: none;
    margin-right: 25px;
    padding: 0 15px;
    transition: all 220ms linear;
}

.tab:last-of-type {
    margin-right: 0;
}

.tab.is-active {
    border: 1px solid #4492EC;
}

.sub-tab-links {
    border-bottom: 1px solid #494949;
    margin: 0 0 30px 0;
    padding: 0;
    width: 100%;
}

.sub-tab {
    border-bottom: 4px solid #2d2d2d;
    cursor: pointer;
    display: inline-block;
    padding: 13px 0;
    margin-right: 40px;
    transition: all 220ms linear;
}

.sub-tab:last-of-type {
    margin-right: 0;
}

.sub-tab:hover {
    border-bottom: 4px solid #494949;
}

.sub-tab.is-active {
    border-bottom: 4px solid #4492EC;
}

.cancel {
    color: #fff;
    height: 26px;
    margin-left: auto;
    padding-right: 36px;
    position: relative;
}

.cancel::after {
    background: #5C5C5C url('../../../../../media/img/icons/closeX.svg') center center no-repeat;
    background-size: 12px auto;
    border-radius: 13px;
    content: '';
    display: block;
    height: 26px;
    position: absolute;
    right: 0;
    top: 0;
    transition: background 110ms linear;
    width: 26px;
}

.cancel:hover::after {
    background: #E84343 url('../../../../../media/img/icons/closeX.svg') center center no-repeat;
    background-size: 12px auto;
}


/* !FORM FOOTER ----------------------------------------------------------------
------------------------------------------------------------------------------*/

.form-footer {
    border-top: 1px solid #494949;
    display: flex;
    padding: 30px 0;
    width: 100%;
}

.form-footer .button {
    margin-left: 28px;
}

.form-footer .button:disabled {
    background-color: #5C5C5C;
    margin-left: 28px;
}

.form-footer .button:first-of-type {
    margin-left: auto;
}
