.upload {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
}

.upload h1 {
    margin-top: 0;
    margin-bottom: 0;
}

.SelectRow {
    display: flex;
    flex-wrap: wrap;
    margin-top: 7px;
    /*margin-bottom: 13px;*/
    width: 100%;
}

.SelectRow .SelectBlock{
    flex: 1 1 250px;
    padding: 15px 20px 0px 20px;
    min-width: 250px;
    /*max-width: 300px;*/
    position: relative;
}

.SelectRow .SelectBlock label {
     display: inline-block;
     letter-spacing: .5px;
     padding-bottom: 5px;
     text-transform: uppercase;
     width: 100%;
 }

.SelectRow .SelectBlock label p {
    display: inline-block;
    letter-spacing: .5px;
    font-size: 0.65rem;
    font-style: italic;
    text-transform: none;
    margin: 0;
}

.upload .css-tlfecz-indicatorContainer {
    color: #ed462f;
}

.upload .flatpickr-input, .upload .event-upload {
    background: #FFFFFF;
    background-size: 14px auto;
    border-color: #cccccc;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    color: #232022;
    /*font-size: 0.9rem;*/
    font-family: "proxima-soft", sans-serif;
    min-height: 38px;
    letter-spacing: 0.5px;
    outline: 0;
    padding: 2px 8px;
    /*transition: all 0.4s ease-out;*/
    width: 100%;
}

.upload input[type="text"]:hover {
    border-color: #b3b3b3;
}

/*.upload input[type="text"]::-webkit-input-placeholder { text-transform: uppercase; }*/
/*.upload input[type="text"]:-moz-placeholder { text-transform: uppercase; }*/
/*.upload input[type="text"]:-ms-input-placeholder { text-transform: uppercase; }*/
/*.upload input[type="text"]:-moz-placeholder{ text-transform: uppercase; }*/

.upload .SelectRow .SelectBlock textarea {
    appearance: none;
    background: #FFFFFF;
    background-size: 14px auto;
    border-color: #cccccc;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    color: #232022;
    font-size: 0.9rem;
    height: 150px;
    letter-spacing: 0.5px;
    outline: 0;
    padding: 4px 30px 0 20px;
    transition: all 0.4s ease-out;
    width: 100%;
    resize: none;
}

.Content {
    /*height: 185px;*/
    border-radius: 17.5px;
    border: 2px dashed rgb(187, 186, 186);
    display: flex;
    flex-direction: row;
    justify-content: center;
    /*padding-top: 16px;*/
    box-sizing: border-box;
    /*width: 100%;*/
}

.Files::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}
.Files::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

.Files::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.Files {
    height: 166px;
    position: relative;
    top: 8px;
    /*background-color: #ececed;*/
    margin-left: 32px;
    /*margin-right: 1px;*/
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1;
    overflow-y: auto;
}

.Actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 2px;
}

.Actions .button-wrapper {
    position: relative;
    right: 5px;
}

.Actions button {
    background-color: #ed462f;
    color: #FFFFFF;
    border: 0;
    border-radius: 17.5px;
    padding: 7px 15px;
    cursor: pointer;
    margin-left: 5px;
    transition: all 0.2s;
}

.Actions .cancel-button {
    background-color: #646464
}

.Actions .clear-image-button {
    background-color: #646464
}

.Actions button:disabled {
    cursor: initial;
    background-color: #cccccc;
    color: #a1a1a1;
}

.Actions button:enabled:hover {
    /*background: #5a5c64;*/
    transform: scale(1.05);
}

.Title {
    margin-bottom: 32px;
    color: #555;
}

.Filename {
    margin-bottom: 8px;
    font-size: 16px;
    color: #e01e3c;
}

.Row {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
    padding: 8px;
    /*overflow: hidden;*/
    box-sizing: border-box;
}

.CheckIcon {
    opacity: 0.5;
    margin-left: 32px;
}

.ProgressWrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}


@media screen and (max-width: 550px) {

    .SelectRow .SelectBlock {
        max-width: 100%;
    }

    .upload input[type="text"] {
        font-size: 16px;
    }

    .Content {
        justify-content: center;
    }

    .Content .Files {
        display: none;
    }

}

/*button {*/
/*    font-size: 14px;*/
/*    display: inline-block;*/
/*    height: 36px;*/
/*    min-width: 88px;*/
/*    padding: 6px 16px;*/
/*    line-height: 1.42857143;*/
/*    text-align: center;*/
/*    white-space: nowrap;*/
/*    vertical-align: middle;*/
/*    -ms-touch-action: manipulation;*/
/*    touch-action: manipulation;*/
/*    cursor: pointer;*/
/*    -webkit-user-select: none;*/
/*    -moz-user-select: none;*/
/*    -ms-user-select: none;*/
/*    user-select: none;*/
/*    border: 0;*/
/*    border-radius: 2px;*/
/*    background: rgba(103, 58, 183, 1);*/
/*    color: #fff;*/
/*    outline: 0;*/
/*}*/

/*button:disabled {*/
/*    background: rgb(189, 189, 189);*/
/*    cursor: default;*/
/*}*/