.pagination {
    align-items: center;
    display: flex;
    padding: 0 34px;
    margin: 20px 0;
}

.pagination-controls {
    align-items: center;
    display: flex;
    margin-left: auto;
}


@media screen and (max-width: 650px) {

    .pagination {
        display: block;
        margin: 10px 0;
    }

    .pagination-info {
        display: none;
    }

    .pagination-controls {
        margin-top: 5px;
        justify-content: center;
    }

}

.pagination[disabled], .pagination-controls[disabled] {
    display: none;
}

.pagination-controls span[disabled] {
    opacity: 0.3;
    pointer-events: none;

}

.pagination-info span {
    font-weight: bold;
}

.page-control {
    display: inline-block;
}

.page-selector {
    border-radius: 2px;
    border: 1px solid #4492EC;
    height: 25px;
    margin: 0 11px;
    text-align: center;
    width: 30px;
}

.left-double-arrow {
    background: white;
    border: 1px solid #4492EC;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    margin-right: 11px;
    transition: background 110ms linear;
    width: 25px;
}

.left-single-arrow {
    background: white;
    border: 1px solid #4492EC;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    margin-right: 31px;
    transition: background 110ms linear;
    width: 25px;
}

.right-single-arrow {
    background: white;
    border: 1px solid #4492EC;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    margin-left: 31px;
    margin-right: 11px;
    transform: scaleX(-1);
    transition: background 110ms linear;
    width: 25px;
}

.right-double-arrow {
    background: white;
    border: 1px solid #4492EC;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    transform: scaleX(-1);
    transition: background 110ms linear;
    width: 25px;
}

.left-double-arrow:hover,
.left-single-arrow:hover,
.right-single-arrow:hover,
.right-double-arrow:hover {
    background: #E6F2FF;
}

.left-double-arrow div {
    background: url('../../../media/img/icons/pagination_doubleChev.svg') center center no-repeat;
    background-size: 50% 50%;
    height: 100%;
    width: 100%;
}

.left-single-arrow div {
    background: url('../../../media/img/icons/pagination_singleChev.svg') center center no-repeat;
    background-size: 50% 50%;
    height: 100%;
    width: 100%;
}

.right-single-arrow div {
    background: url('../../../media/img/icons/pagination_singleChev.svg') center center no-repeat;
    background-size: 50% 50%;
    height: 100%;
    width: 100%;
}

.right-double-arrow div {
    background: url('../../../media/img/icons/pagination_doubleChev.svg') center center no-repeat;
    background-size: 50% 50%;
    height: 100%;
    width: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}

/* modal */

@media screen and (max-width: 550px) {

    .ReactModalPortal .ReactModal__Content.ReactModal__Content--after-open{
        width: 100%;
        height: 100%;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;

    }

}