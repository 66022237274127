/* Photo Gallery --------------------------------------------------------------- */

.photo-gallery {
    background: #fff;
    color: #000000;
    /*min-height: 76.6vh;*/
}

.photo-gallery .sticky-wrapper {
    background-color: #FFFFFF;
    position: sticky;
    top: 148px;
    z-index: 10;
    padding: 20px 0 10px 0;
}

.photo-gallery .input-wrapper {
    align-items: center;
    padding: 0 34px;
    /*margin: 20px 0 0 0;*/
    display: flex;
}

.photo-gallery .input-wrapper svg {
    background: center center no-repeat;
    background-size: contain;
    cursor: pointer;
    height: 29px;
    margin: 0 0 0 15px;
    padding: 0;
    width: 29px;
    transition: 0.2s;
    -webkit-transition: 0.2s;
}

.photo-gallery .input-wrapper svg:hover {
    transform: scale(1.2);
}

.photo-gallery .input-wrapper svg[disabled] {
    display: none;
    cursor: not-allowed;
}

.photo-gallery .input-wrapper img {
    height: 29px;
    width: 29px;
    cursor: initial;
    margin: 0 0 0 15px;
 }

.photo-gallery .input-wrapper img[disabled] {
    display: none;
}

@media screen and (max-width: 550px) {

    .photo-gallery .input-wrapper {
       margin-bottom: 0;
    }

}

/* Floating Action Button --------------------------------------------------------------- */

.fab {
    cursor: pointer;
    width: 70px;
    height: 70px;
    background-color: red;
    border-radius: 50%;
    box-shadow: 0 6px 10px 0 #666;
    transition: all 0.1s ease-in-out;

    font-size: 50px;
    color: white;
    text-align: center;
    line-height: 70px;

    position: fixed;
    right: 30px;
    bottom: 28px;
    z-index: 5;
}

.fab:hover {
    box-shadow: 0 6px 14px 0 #666;
    transform: scale(1.05);
}

@media screen and (max-width: 550px) {

    .fab {
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;

        bottom: 20px;
    }

}

/* Select All Checkbox --------------------------------------------------------------- */
.photo-gallery .selectAll-Pagination-row {
    display: flex;
    margin-left: 24px;
    align-items: center;
}

.photo-gallery .selectAll-Pagination-row .MuiCheckbox-colorPrimary {
    color: #4492EC;
}

/*.photo-gallery .selectAll-Pagination-row .MuiIconButton-root {*/
/*    height: 60%;*/
/*    top: 11px;*/
/*}*/

.photo-gallery .selectAll-Pagination-row .MuiButtonBase-root.Mui-disabled {
    display: none;
}

.photo-gallery .selectAll-Pagination-row .pagination {
    flex: 12;
    /*padding-left: 0;*/
}

@media screen and (max-width: 550px) {

    .photo-gallery .selectAll-Pagination-row {
        margin-left: 0;
    }

    .photo-gallery .selectAll-Pagination-row .MuiIconButton-root{
        display: none;
    }
}
