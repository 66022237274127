.row {
    width: 100%;
    display: flex;
    overflow-x: auto;
    /*flex-direction: row;*/
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: block;
}

.row::-webkit-scrollbar {
    height: 0px; /* remove scrollbar space */
    background: transparent; /* optional: just make scrollbar invisible */
}

.column {
    width: 125px;
    margin: 8px;
    /*float: left;*/
    height: 90px;
    background-size: cover;
    border-style: solid;
    border-color: #cccccc;
    display: flex;
}

.column > span {
    height: 100%;
    width: 100%;
    display: block;
    text-align: center;
    font-size: large;
    word-wrap: break-word;
}

.column:hover {
    /*fill-opacity: 80%;*/
    /*background-image: none;*/
    opacity: 0.8;
    background-color: rgba(255, 255, 255, .8);
}
