/* !INPUT WRAPPERS -------------------------------------------------------------
------------------------------------------------------------------------------*/

.input-wrapper {
    padding: 10px 15px;
}
.input-wrapper.third {
    width: 33%;
}

.input-wrapper.half {
    width: 50%;
}

.input-wrapper.full {
    width: 100%;
}


/* !LABEL ----------------------------------------------------------------------
------------------------------------------------------------------------------*/

.label {
    font-size: 12px;
    font-weight: 300;
}


/* !INPUT ----------------------------------------------------------------------
------------------------------------------------------------------------------*/

.input {
    background-color: #454545;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 13px;
    height: 40px;
    margin-top: 11px;
    padding: 0 15px;
    transition: all 220ms linear;
    width: 100%;
}

.input:focus {
    background-color: #585858;
    outline: none;
}

.input:hover {
    background-color: #585858;
    outline: none;
}

.input:disabled {
    background-color: #363636;
    cursor: not-allowed;
    opacity: 0.6;
    outline: none;
}
