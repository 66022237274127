.nav {
    width: 100%;
    margin-top: 2%;
    list-style: none;
    text-align: center;
    padding: 0;
}

.nav li {
    display: inline;
}

.nav li a {
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all .29s ease-in-out;
    -o-transition: all .29s ease-in-out;
    transition: all .29s ease-in-out;
    padding: 10px;
}

.nav li a:hover {
    color: red
}

@media screen and (max-width: 705px) {
    .nav {
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
        padding-left: 0;
    }

    .nav li {
        display: inline-flex;
    }
}