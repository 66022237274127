/* FILTER BAR ------------------------------------------------------------------
----------------------------------------------------------------------------- */
.filter-bar {
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 88px;
    width: 100%;
    z-index: 20;
}

.filter-bar .top {
    align-items: center;
    background: #fff;
    cursor: pointer;
    display: flex;
    height: 60px;
    justify-content: space-between;
    opacity: 1;
    padding: 15px 20px;
    position: relative;
    transition: all 0.4s ease-in-out;
    z-index: 20;
}

.filter-bar .top:hover {
    background: #f3f3f3;
}

@media screen and (max-width: 550px) {
    .filter-bar .top:hover .flyout-toggle {
        display: none;
    }
}

.top .active-filters {
    font-size: 0.9rem;
    font-style: italic;
    margin: 0 20px 0 0;
    opacity: 1;
    padding: 0;
    transition: all 0.2s ease-in-out;
}

.top .active-filters li {
    display: inline-block;
    margin-left: 20px;
}

.top .active-filters li:first-of-type {
    background: url('../../../media/icon-caret-down-red.svg') center left no-repeat;
    background-size: 15px auto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    margin-left: 0;
    padding-left: 23px;
    text-transform: uppercase;
}

.filter-bar .flyout {
    align-items: center;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-wrap: wrap;
    left: 0;
    justify-content: flex-start;
    opacity: 0;
    position: absolute;
    right: 0;
    top: -800px;
    transition: all 0.4s ease-in-out;
    z-index: 10;
}

.filter-bar .flyout .flyout-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 7px;
    margin-bottom: 13px;
    width: 100%;

}

.filter-bar .flyout .filter {
    flex: 1 1 250px;
    padding: 20px 20px 0px 20px;
    min-width: 250px;
    max-width: 300px;
    position: relative;
}

.filter-bar .flyout .filter label {
    display: inline-block;
    letter-spacing: .5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    width: 100%;
}

@media screen and (max-width: 550px) {

    .filter-bar .flyout .filter {
        max-width: 100%;
    }

}
/* expanded state --------------------------------------------------------------
----------------------------------------------------------------------------- */

.filter-bar.is-expanded {

}


.filter-bar.is-expanded .flyout {
    opacity: 1;
    top: 60px;
}

.filter-bar.is-expanded .top .active-filters li:first-of-type {
    background: url('../../../media/icon-caret-up-black.svg') center left no-repeat;
    background-size: 15px auto;
}

@media screen and (max-width: 550px) {

    .filter-bar.is-expanded .flyout {
        height: calc(100vh - 148px);
        overflow-y: scroll;
    }

}

/* flyout footer ---------------------------------------------------------------
----------------------------------------------------------------------------- */
.flyout-footer {
    background: #f3f3f3;
    display: flex;
    /*flex-wrap: wrap;*/
    padding: 20px;
    margin-top: 30px;
    width: 100%;
}

.flyout-footer .button, .flyout-footer .close-button {
    background: #646464;
    color:#fff;
    border: 0;
    border-radius: 17.5px;
    cursor: pointer;
    display: inline-block;
    font-size: .9rem;
    height: 35px;
    letter-spacing: .5px;
    margin-right: 5px;
    padding: 11px 40px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
}

.flyout-footer .apply-button {
    background: red;
    color:#fff;
    border: 0;
    border-radius: 17.5px;
    cursor: pointer;
    display: inline-block;
    font-size: .9rem;
    height: 35px;
    letter-spacing: .5px;
    padding: 11px 20px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    position: absolute;
    right: 10px;
}

@media screen and (max-width: 550px) {

    .flyout-footer {
        display: block;
    }

    .flyout-footer .button {
        width: 58%;
        margin-bottom: 7px;
    }

    .flyout-footer .close-button {
        margin-right: 0;
        width: 40%;
        justify-content: right;
    }

    .flyout-footer .apply-button {
        position: relative;
        width: 100%;
        right: 0;
        /*margin-left: 5px;*/
        justify-content: center;
    }

}

/* bottom close button (mobile only) -------------------------------------------
----------------------------------------------------------------------------- */

/*.close-button {*/
/*    background: #f3f3f3;*/
/*    padding: 20px;*/
/*    width: 100%;*/
/*}*/

/*.close-button .button {*/
/*    width: 100%;*/
/*}*/


/*!* date pickers / ranges -------------------------------------------------------*/
/*----------------------------------------------------------------------------- *!*/

/*.filter .react-datepicker__input-container {*/
/*    width: 100%;*/
/*}*/

/*.react-datepicker__close-icon::after {*/
/*    background: #e01e3c;*/
/*    transition: all 0.2s ease-in-out;*/
/*}*/

/*.react-datepicker__close-icon:hover::after {*/
/*    background: #161616;*/
/*}*/

/*.date-range-button {*/
/*    background: #fff;*/
/*    border: 1px solid #000;*/
/*    border-radius: 17.5px;*/
/*    color: #000;*/
/*    cursor: pointer;*/
/*    display: inline-block;*/
/*    font-family: 'alternate-gothic-no-2-d','sans-serif';*/
/*    font-size: 0.9rem;*/
/*    height: 35px;*/
/*    letter-spacing: 0.5px;*/
/*    line-height: 35px;*/
/*    padding: 0 20px;*/
/*    margin-left: 10px;*/
/*    text-transform: uppercase;*/
/*}*/

/*.date-range-button:first-of-type {*/
/*    margin-left: 0;*/
/*}*/

/*.date-range-button:hover {*/
/*    background: #161616;*/
/*    border: 1px solid #161616;*/
/*    color: #fff;*/
/*}*/

/*.filter-bar .flyout .filter.date-ranges {*/
/*    min-width: 290px;*/
/*    max-width: 600px;*/
/*}*/

/*@media screen and (max-width: 550px) {*/

/*    .filter-bar .flyout .filter.date-ranges {*/
/*        min-width: 100%;*/
/*    }*/

/*    .date-range-button {*/
/*        padding: 0;*/
/*        text-align: center;*/
/*        width: 30%;*/

/*    }*/


/*}*/

/* inputs ----------------------------------------------------------------------
----------------------------------------------------------------------------- */

.filter .css-tlfecz-indicatorContainer {
    color: #ed462f;
}

.filter-bar .flatpickr-input {
    background: #FFFFFF;
    background-size: 14px auto;
    border-color: #cccccc;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    color: #333333;
    /*font-size: 0.9rem;*/
    font-family: "proxima-soft", sans-serif;
    height: 35px;
    letter-spacing: 0.5px;
    outline: 0;
    padding: 2px 8px;
    /*transition: all 0.4s ease-out;*/
    width: 100%;
}

.filter-bar input[type="text"]:hover {
    border-color: #b3b3b3;
}

.filter-bar .flatpickr-input::after {
    background-image: url(../../../media/img/icons/cal_icon_red.svg);
    content: '';
    display: block;
    height: 8px;
    position: absolute;
    right: 20px;
    top: 15px;
    width: 12px;
    pointer-events: none;
}

/*.filter-bar input[type="text"]::-webkit-input-placeholder { text-transform: uppercase; }*/
/*.filter-bar input[type="text"]:-moz-placeholder { text-transform: uppercase; }*/
/*.filter-bar input[type="text"]:-ms-input-placeholder { text-transform: uppercase; }*/
/*.filter-bar input[type="text"]:-moz-placeholder{ text-transform: uppercase; }*/

@media screen and (max-width: 550px) {

    .filter-bar input[type="text"] {
        font-size: 16px;
    }

}

/* custom select */

/*.custom-select {*/
/*    display: inline-block;*/
/*    height: 35px;*/
/*    padding-right: 25px;*/
/*    position: relative;*/
/*    width: 100%;*/
/*    background: rgb(236, 236, 237);*/
/*    border-radius: 17.5px;*/
/*    overflow: hidden;*/
/*}*/

/*.custom-select:after {*/
/*    background-image: url(../../../media/icon-caret-down-red.svg);*/
/*    content: '';*/
/*    display: block;*/
/*    height: 8px;*/
/*    position: absolute;*/
/*    right: 20px;*/
/*    top: 15px;*/
/*    width: 12px;*/
/*    pointer-events: none;*/
/*}*/

/*.custom-select:hover {*/
/*    background: #161616;*/
/*}*/

/*.custom-select:checked:after {*/
/*    transform: translate(-50%, -50%) rotate(180deg) scaleX(-1) scaleY(1.75);*/
/*}*/

/*.custom-select select {*/
/*    box-shadow: none;*/
/*    color: rgb(117, 117, 117);*/
/*    font-size: 0.9rem;*/
/*    letter-spacing: 0.5px;*/
/*    text-transform: uppercase;*/
/*    -webkit-appearance: none;*/
/*    width: 115%;*/
/*    background: none transparent;*/
/*    border-width: initial;*/
/*    border-style: none;*/
/*    border-color: initial;*/
/*    border-image: initial;*/
/*    outline: none;*/
/*    padding: 10px 20px;*/
/*    cursor: pointer;*/
/*    display: block;*/
/*}*/

/*.custom-select select:hover {*/
/*    color: #ffffff;*/
/*    height: 100%;*/
/*}*/

/* Checkbox */

.filter-bar .flyout .filter .MuiFormControlLabel-root {
    display: inline-flex;
    margin-right: 0;
    margin-top: -10px;
    padding: 0;
}

.filter-bar .flyout .filter .MuiFormControlLabel-root .MuiTypography-body1 {
    size: 0.9rem;
    font-family: inherit;
}