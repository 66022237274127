/* !GLOBAL ---------------------------------------------------------------------
------------------------------------------------------------------------------*/

/*html {*/
/*    box-sizing: border-box;*/
/*    height: 100%;*/
/*}*/

/**, *:before, *:after {*/
/*    box-sizing: inherit;*/
/*}*/

a {
    cursor: pointer;
    text-decoration: none;
    transition: all 220ms linear;
}

button {
    appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
}


/* !STRUCTURE ------------------------------------------------------------------
------------------------------------------------------------------------------*/

.wrapper {
    background: #2d2d2d;
    display: flex;
    margin-left: 235px;
    min-width: calc(100% - 235px); /* minus = fixed sidebar width */
}

/* expanded state */

.drawer-expanded .wrapper .main {
    opacity: 0.6;
    transform: translate3d(790px, 0, 0); /* translate amount = fixed drawer width */
}

.drawer-expanded-narrow .wrapper .main {
    opacity: 0.6;
    transform: translate3d(406px, 0, 0); /* translate amount = fixed drawer width */
}

.is-hidden {
    display: none !important;
}

/* !SHARED FORM ELEMENTS -------------------------------------------------------
------------------------------------------------------------------------------*/

.button.submit {
    background-color: #4A90E2;
    color: white;
    height: 40px;
    width: 147px;
}

.button.submit:disabled {
    background-color: #999;
}

.button.save {
    background: transparent;
    border: 1px solid #fff
}

.text-button {
    color: #4A90E2;
    cursor: pointer;
    display: inline-block;
    margin-left: 22px;
    font-weight: bold;
}

/* !NOTIFICATIONS (https://www.npmjs.com/package/react-toastify) ---------------
------------------------------------------------------------------------------*/

.notification {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.notification.error {
    background-color: #B71515;
    color: #fff;
}

.notification.success {
    background-color: #219134;
    color: #fff;
}

.notification button {
    color: #fff;
    opacity: 1;
}

.notification div:last-child {
    background: rgba(255, 255, 255, 0.2);
}


/* !MODALS (http://reactcommunity.org/react-modal/) ----------------------------
------------------------------------------------------------------------------*/

.react-modal-overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0px;
    display: flex;
    left: 0px;
    position: fixed;
    justify-content: center;
    right: 0px;
    top: 0px;
    z-index: 2;
}

.react-modal-content {
    background: rgb(255, 255, 255);
    outline: none;
    overflow: auto;
    min-height: 100px;
    padding: 20px;
    position: absolute;
    transition: all 300ms linear;
    width: 500px;
}

/* modal contents */

.modal {
    padding: 30px;
    position: relative;
    transition: all 300ms linear;
}

/* loading state */

.modal.is-loading {
    pointer-events: none;
}

.modal.is-loading::after {
    background: #fff url('../../media/img/misc/loader-grey.gif') center bottom no-repeat;
    background-size: 50px auto;
    content: 'csv file processing';
    display: block;
    font-size: 14px;
    height: 65px;
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
}

.modal.is-loading form {
    opacity: 0;
}

.modal-header {
    color: #B71515;
    font-size: 26px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: center;
}

.modal-body {
    margin: 30px 0;
    text-align: center;
}

.modal-actions {
    align-items: center;
    display: flex;
    justify-content: space-around;
}

.modal-actions a:first-child {
    background: #FFF;
    border-radius: 2px;
    border: 1px solid #4492EC;
    color: #4492EC;
    font-weight: bold;
    letter-spacing: .25px;
    padding: 12px 50px;
}

.modal-actions a:last-child {
    border-radius: 2px;
    background: #4492EC;
    color: #FFF;
    font-weight: bold;
    letter-spacing: .25px;
    padding: 12px 50px;
}

.modal-errors {
    margin-bottom: 20px;
}

.modal-errors .error-header {
    color: #B71515;
    font-size: 15px;
    margin: 0;
    padding-bottom: 15px;
    text-align: center;
}

.modal-errors .error-list {
    border: 1px solid #DCDCDC;
    height: 160px;
    margin: 0;
    overflow: scroll;
    padding: 15px;
}

.modal-errors .error-list-item {
    list-style: none;
    margin: 15px 0;
}

.modal-errors .error-list-item span {
    font-weight: 300;
}

.modal-errors .error-list-item:first-of-type {
    margin-top: 0;
}

.modal-errors .error-list-item:last-of-type {
    margin-bottom: 0;
}


/* LIST FALLBACKS (TODO move to a <List> component) ----------------------------
------------------------------------------------------------------------------*/

.list-fallback {
    border-top: 1px solid #DCDCDC;
    padding: 70px;
    text-align: center;
}

.list-fallback-planning {
    border: 1px solid #DCDCDC;
    margin: 26px 0;
    padding: 43px;
    text-align: center;
}

/* LIST LOADERS (TODO see above ^) ---------------------------------------------
------------------------------------------------------------------------------*/

.programs-body, .poskits-body {
    position: relative;
}

.programs-body.is-loading::after, .poskits-body.is-loading::after {
    background: #fff url('../../media/img/misc/loader-grey.gif') center center no-repeat;
    background-size: 50px auto;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
