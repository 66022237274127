.fallback-message {
    font-size: large;
    font-weight: bold;
    margin: 20px;
    text-align: center;
    width: 100%;
    min-height: 60vh;
}

/* Photo --------------------------------------------------------------- */

.photos {
    /*background-color: black;*/
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1400px;
    padding: 0 10px;
}

.photos .item {
    padding: 10px;
    position: relative;
    width: 25%;
}

.photos .item .img-wrapper {
    position: relative;
    line-height: 0;
}

.photos .item img{
    cursor: pointer;
    height: auto;
    width: 100%;
}

.photos .item-info {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    font-size: 13.5px;
    padding: 15px;
    border-color: rgba(0,0,0,.2);
    border-style:solid;
    border-width: 1px;
}

.photos .item-info .full {
    margin: 0;
    padding: 0;
    width: 100%;
}

.photos .item-info .half {
    margin: 0;
    padding: 0;
    width: 50%;
}

.photos .item-info .link-icon {
    position: absolute;
    right: 15px;
    bottom: 20px;
    cursor: pointer;
    transition: 0.2s;
    -webkit-transition: 0.2s;
}

.photos .item-info .link-icon:hover {
    transform: scale(1.2);
}

.photos .item-info li {
    list-style: none;
    margin: 4px 0;
}

.photos .item-info li span {
    font-weight: bold;
}


@media screen and (max-width: 1000px) {
    .photos .item {
        width: 33.33%;
    }
}

@media screen and (max-width: 800px) {
    .photos .item {
        width: 50%;
    }
}

@media screen and (max-width: 550px) {
    .photos .item {
        width: 100%;
    }
}

/* Photo Selection------------------------------------------------------*/

.photos .item .img-wrapper label {
    position: absolute;
    background: #ffffffcc center center;
    left: 15px;
    top: 13px;
    height: 17px;
    width: 17px;
    border-radius: 4px;
}

.photos .item .img-wrapper label:hover {
    background: #5a5c64;
}

.item .img-wrapper span{
    position: absolute;
}

.item .img-wrapper label svg path{
    color: #4492EC;
}

/* Photo Verification------------------------------------------------------*/

.item .img-wrapper .verification-icon{
    position: relative;
}

.item .img-wrapper .verification-icon svg{
    position: absolute;
    right: 15px;
    top: 13px;
    z-index: 2;
}

.item .img-wrapper .verification-icon::after{
    position: absolute;
    content: '';
    right: 20px;
    top: 19px;
    background: #FFFFFF;
    height: 12px;
    width: 13px;
    z-index: 1;
}

.item .img-wrapper .verification-icon[disabled] {
    display: none;
    cursor: none;
}

/* Photo Rotation------------------------------------------------------*/

.item .img-wrapper:hover .left-rotate{
    display: flex;
    justify-content: center;
    position: absolute;
    left: 10px;
    bottom: 8px;
    border-radius: 17px;
    border: 0.1rem solid #4492ec;
    height: 32px;
    width: 32px;
    background: #ffffffcc;
    cursor: pointer;
}

.item .img-wrapper .left-rotate {
    display: none;
    cursor: none;
}

.item .img-wrapper:hover .right-rotate{
    display: flex;
    justify-content: center;
    position: absolute;
    right: 10px;
    bottom: 8px;
    border-radius: 17px;
    border: 0.1rem solid #4492ec;
    height: 32px;
    width: 32px;
    background: #ffffffcc;
    cursor: pointer;
}

.item .img-wrapper .right-rotate {
    display: none;
    cursor: default;
}

@media screen and (max-width: 550px) {
    .item .img-wrapper:hover .left-rotate {
        display: none;
        cursor: default;
        pointer-events: none;
    }

    .item .img-wrapper:hover .right-rotate {
        display: none;
        cursor: default;
        pointer-events: none;
    }
}


/* breakpoint overrides ------------------------------------------------------*/

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 800px) {

}

@media screen and (max-width: 550px) {

    .photos .top .title {
        font-size: 14px;
    }

    .photos .row {
        display: flex;
    }

    .no-photos {
        margin-top: 40px;
    }

}
