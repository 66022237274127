.sidebar {
    background: #2e2e2e;
    height: 100%;
    position: fixed;
    width: 235px;
}


/* !SIDEBAR HEADER -------------------------------------------------------------
------------------------------------------------------------------------------*/

.sidebar-header {
    align-items: center;
    background: #B71515;
    display: flex;
    height: 87px;
    justify-content: center;
}

.sidebar-header .logo {
    background: url('../../../../../media/img/logos/diageoMKTG_logoLockup.svg') center center no-repeat;
    background-size: 100% 100%;
    color: #fff;
    font-size: 13px;
    height: 22.3px;
    margin: 0;
    overflow: hidden;
    text-indent: -1000em;
    width: 145px;
}


/* !SIDEBAR INNER --------------------------------------------------------------
------------------------------------------------------------------------------*/

.sidebar-inner {
    display: flex;
    flex-direction: column;
    height: calc(100% - 137px); /* - fixed header height */
    overflow: auto;
}


/* !SIDEBAR LINKS/NAV ----------------------------------------------------------
------------------------------------------------------------------------------*/

.sidebar-links {
    background: #000;
    position: relative;
    padding: 37px 23px;
}

.sidebar-links li {
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    list-style: none;
    margin: 40px 0;
}

.sidebar-links li:first-of-type {
    margin-top: 0;
}

.sidebar-links li:last-of-type {
    margin-bottom: 0;
}

.sidebar-links li a {
    color: #fff;
}

.sidebar-links li a:hover {
    color: #4A90E2;
}

/* .sidebar-links li a.is-active {
   color: #4A90E2;
} */

.sidebar-links li.icon-upload a {
    background: url('../../../../../media/img/icons/whiteUpload.svg') left center no-repeat;
    background-size: auto 79%;
    padding-left: 25px;
}

/* .sidebar-links li.is-active {
   background: url('../../../img/icons/currentSectionBlueArrow.svg') right center no-repeat;
} */

.sidebar-select {
    align-items: center;
    background: #C72222;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: space-between;
    /* line-height: 20px; */
    padding: 15px 24px;
    position: relative;
    width: 100%;
}


.sidebar-select .select-ellipsis {
    background: url('../../../../../media/img/icons/meatballs_white.svg') center center no-repeat;
    display: inline-block;
    height: 20px;
    transition: linear all .22s;
    width: 20px;
}

/* .sidebar-select:hover .select-ellipsis{
   transform: rotateZ(90deg);
}
.sidebar-select.is-active .select-ellipsis{
   transform: rotateZ(90deg);
} */
