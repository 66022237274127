.user {
    align-items: center;
    background-color: #fff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    height: 60px;
    justify-content: flex-end;
    padding: 0 24px 0 24px;
    width: 100%;
}

.user + .user {
    margin-top: 16px;
}

.user-name {
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
    margin-right: auto;
}

.user .custom-select {
    background: transparent url('../../../../../media/img/icons/selectMenuCaretBlack.svg') right 20px center no-repeat;
    border: #4492EC 1px solid;
    margin-top: 0;
}

.user .custom-select:hover {
    background: #eee url('../../../../../media/img/icons/selectMenuCaretBlack.svg') right 20px center no-repeat;
}

.user .custom-select select {
    color: #000;
}

.user .custom-select.disabled {
    background: #ddd url('../../../../../media/img/icons/selectMenuCaretBlack.svg') right 20px center no-repeat;;
}

.user-role {
    width: 292px;
}

.user-edit {
    background: url('../../../../../media/img/icons/edit.svg') center center no-repeat;;
    background-size: 100% 100%;
    border-radius: 2px;
    cursor: pointer;
    height: 30px;
    margin: 0 0 0 24px;
    width: 30px;
}

.user-delete {
    background: url('../../../../../media/img/icons/delete.svg') center center no-repeat;;
    background-size: 100% 100%;
    border-radius: 2px;
    cursor: pointer;
    height: 30px;
    margin: 0 0 0 24px;
    width: 30px;
}