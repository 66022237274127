.filters {
    background: #000;
    margin: 0;
    padding: 37px 23px;
    position: relative;
}

.filters.no-bg {
    background: none;
}

.filters li {
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    font-weight: 300;
    list-style: none;
    margin: 20px 0;
    position: relative;
}

.filters li:hover a {
    color: #fff;
}

.filters li:hover .filter-ellipsis {
    background: url('../../../../../media/img/icons/meatballs_white.svg') center center no-repeat;
}

.filters li:first-of-type {
    margin-top: 0px;
}

.filters li:last-of-type {
    margin-bottom: 0;
}

.filters li a {
    color: #B1B1B1;
    display: inline-block;
    width: 100%;
}

.filters li a {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 149px;
}

.filters li .filter-ellipsis {
    background: url('../../../../../media/img/icons/meatballs_gray.svg') center center no-repeat;
    bottom: 0;
    display: inline-block;
    height: 20px;
    position: absolute;
    right: 0;
    transition: linear background .22s;
    width: 20px;
}

.filters li a.is-active ~ .filter-ellipsis {
    background: url('../../../../../media/img/icons/meatballs_white.svg') center center no-repeat;
}

.filters li a.is-active {
    color: #ffffff;
    font-weight: bold;
}
