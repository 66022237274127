/* custom radio */

.radio-block {
    display: block;
    width: 50%;
}

.radio-wrapper {
    cursor: pointer;
    font-size: 13px;
    height: 20px;
    line-height: 22px;
    margin: 15px 0;
    padding: 0 0 0 35px;
    position: relative;
}

.radio-group-body {
    padding-top: 9px;
}

.radio-wrapper input[type='radio'] {
    display: none;
}

.radio-wrapper span {
    background-color: #585858;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    display: inline-block;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    transition: background-color 50ms ease-out;
    width: 20px;
}

.radio-wrapper span span {
    border-radius: 6px;
    background-color: #434343;
    display: inline-block;
    height: 12px;
    left: 4px;
    position: absolute;
    top: 4px;
    transition: background-color 50ms ease-out;
    width: 12px;
}

.radio-wrapper:hover > span > span {
    background-color: #7D7D7D;
}

.radio-wrapper input:checked ~ span {
    background-color: #4492EC;
}

.radio-wrapper input:checked ~ span span {
    background-color: #fff;
}

.radio-wrapper:hover input:checked ~ span {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.9);
}

/* ========================== Disabled ========================== */

.radio-group-body.disabled {
    cursor: not-allowed;
}


.radio-wrapper.disabled {
    pointer-events: none;
}