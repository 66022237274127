.custom-view {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    min-height: 600px;
}

.custom-view h4 {

}

.custom-view p {
    font-size: 14px;
}

.custom-view a {
    color: #4492EC;
    text-decoration: underline;
}

.coming-soon-wrapper {
    align-items: center;
    background-color: #000;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;
}

.coming-soon-text-box {
    background-color: #000;
    color: #fff;
    display: flex;
    font-family: 'proxima-soft', serif;
    font-size: 2rem;
    font-weight: 700;
    padding: 100px 50px;
}
